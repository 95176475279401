import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client'
import { concat } from '@apollo/client/link/core'
import { getSession } from 'next-auth/react'
import { getSessionStore } from './helper'

export interface headers {
  store: string
  authorization: string
}

const GRAPHQL_API_URL = process.env.NEXT_PUBLIC_GRAPHQL
const DEFAULT_STORE = process.env.NEXT_PUBLIC_MAG_STORE_CODE

const getAuthToken = async (): Promise<string | null> => {
  try {
    const session: any = getSessionStore() || (await getSession())
    return session?.token || null
  } catch (error) {
    console.error('Error fetching session token:', error)
    return null
  }
}

const httpLink = new HttpLink({
  uri: GRAPHQL_API_URL,
})

const authMiddleware = new ApolloLink(async (operation, forward) => {
  const token = await getAuthToken()

  const headers: headers = {
    store: DEFAULT_STORE,
    authorization: '',
  }

  if (token) {
    headers.authorization = `Bearer ${token}`
  }

  operation.setContext({ headers })
  return forward(operation)
})

const link = concat(authMiddleware, httpLink)
const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
})

export default client
