import { useModalAction } from '@components/common/modal/modal.context'
import Button from '@components/ui/button'
import cn from 'classnames'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useForm } from 'react-hook-form'
import { FC, useContext, useEffect, useState } from 'react'
import { UserContext } from '@contexts/user/user.context'
import { IUser } from '@contexts/user/user.utils'
import { toast } from 'react-toastify'
import saveListService from '@services/savelistservice'
import { useMutation } from 'react-query'
import OutlineButton from '@components/ui/outline-button'
import { IoMdAdd } from 'react-icons/io'
import SaveListPopupTable from './savelist-table'
import { createPricePayload, getCurrentPrice } from '@utils/helper'
import { QuickEntryItem } from './savelist.utils'

const SaveListPopUp: FC<any> = ({ data }) => {
  const { list, listSkus, getSavedLists, cancelEdit } = data
  const { t: translate } = useTranslation('savelist')
  const { user } = useContext(UserContext) as IUser
  const router = useRouter()
  const { closeModal } = useModalAction()
  const [quickEntryList, setQuickEntryList] = useState<QuickEntryItem[]>([])

  useEffect(() => {
    if (list?.product_skus?.length) {
      const defaultQuickEntryList = list.product_skus.map((item) => ({
        sku: item.sku,
        qty: listSkus[list.list_id][item.sku],
        price: getCurrentPrice(createPricePayload(item)),
        min_sale_qty: item.min_sale_qty,
        max_sale_qty: item.max_sale_qty,
      }))
      setQuickEntryList(defaultQuickEntryList)
    }
  }, [])

  const updateQuickEntryItem = (item: QuickEntryItem, itemIndex: number) => {
    setQuickEntryList((prevList) => [
      ...prevList.slice(0, itemIndex),
      item,
      ...prevList.slice(itemIndex + 1),
    ])
  }

  const addItemToNewQuickEntryList = () => {
    setQuickEntryList((prevList) => [
      ...prevList,
      { sku: '', qty: 1, price: 0.0 },
    ])
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>()

  const { mutate: updateList, isLoading: updateListLoading } = useMutation(
    (payload: any) => {
      return saveListService.updateSavedList(list.list_id, payload)
    },
    {
      onSuccess: async (response: any) => {
        toast.success(response)
        getSavedLists()
        cancelEdit()
        closeModal()
      },
      onError: (err: Error) => {
        toast.error(`Error list updation: ${err?.message}`, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'toast-error',
        })
      },
    },
  )

  const updateSavedListwithNewItems = async () => {
    let payload = {
      list_name: '',
      skus: [] as string[],
      qty: [] as number[],
      customer_id: user?.id,
    }

    const isDuplicationOrDoesNotExist = quickEntryList.find((item) => {
      if (item.sku.length) {
        payload.skus.push(item.sku)
        payload.qty.push(Number(item.qty))
      }
      return item.alreadyExists || item.doesNotExists
    })

    if (isDuplicationOrDoesNotExist) return

    updateList(payload)
  }

  return (
    <div
      className={cn('w-full md:w-[700px] lg:w-[800px] xl:w-[900px]  relative')}
    >
      <div className="mx-auto overflow-hidden rounded-lg bg-brand-light p-5">
        <SaveListPopupTable
          quickEntryList={quickEntryList}
          updateQuickEntryItem={updateQuickEntryItem}
        />
        <OutlineButton
          className="border-none flex underline my-5"
          onClick={addItemToNewQuickEntryList}
          data-testid="savelist-addsku-popup-button-add-addmoresku"
        >
          {translate('text-add-more-sku')} <IoMdAdd fontSize={18} />
        </OutlineButton>
        <form
          onSubmit={handleSubmit(updateSavedListwithNewItems)}
          noValidate
          className="mt-3"
        >
          <div className="text-right">
            <OutlineButton
              className="h-11 md:h-[45px] w-24 rounded border-1"
              onClick={closeModal}
              data-testid="savelist-addsku-popup-button-cancel-addmoresku"
            >
              {translate('text-cancel')}
            </OutlineButton>
            <Button
              className=" whitespace-nowrap w-24 mt-2 ml-2 h-11 md:h-[44px] "
              variant="formButton"
              type="submit"
              loading={updateListLoading}
              data-testid="savelist-addsku-popup-button-add"
            >
              {translate('text-add')}
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default SaveListPopUp
