const countryCode = process.env.NEXT_PUBLIC_WEBSITE_COUNTRY || 'US'

const GET = {
  // USER
  USER: '/fsd/customers/me',
  USER_ORDER: '/fsd/customer/orders',

  // SHOP
  SHOP_DEPARTMENT: '/get-categories/list?searchCriteria=[]',
  SHOP_BRAND: '/get-brands-manufacturer',
  TESTIMONIALS: '/fsd/get-customer-testimonials',
  REGIONS: `/directory/countries/${countryCode}`,
  MAG_CONFIG: '/getMageConfig',
}
export default GET
