import axios from 'axios'
import mag_categories from '../../../public/api/mag_categories.json'
const categories = mag_categories?.items

let token: string

export const getMagentoBearerToken = async () => {
  // @TODO: put a time check on token...
  if (token === '') {
    token = await axios.post(
      `${process.env.NEXT_PUBLIC_MAG_URL}/index.php/rest/default/V1/integration/admin/token`,
      {
        username: process.env.NEXT_PUBLIC_MAG_USER,
        password: process.env.NEXT_PUBLIC_MAG_PWD,
      },
    )
  }

  return token
}

export const getCategories = async () => {
  // let mag_cats = categories;
  // if (!categories) {
  //   const token = await getMagentoBearerToken();
  //   const config = {
  //     headers: { Authorization: 'bearer ' + token },
  //   };
  //   const response = await axios.get(
  //     `${process.env.NEXT_PUBLIC_MAG_URL}/index.php/rest/V1/get-categories/list?searchCriteria=[]`,
  //     config
  //   );
  //   mag_cats = response.data.items;
  // }

  return categories
}

export const getChildrenCategories = (cat_id: number) => {
  const children = categories.filter((c) => c.parent_id === String(cat_id))

  return children
}

export const getCategoryById = async (id: number) => {
  if (id === 0) return []

  let items = categories.find((cat: any) => Number(cat.id) === id)

  if (!items) {
    const token = await getMagentoBearerToken()
    const config = {
      headers: { Authorization: 'bearer ' + token },
    }
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_MAG_URL}/index.php/rest/V1/get-categories/${id}`,
      config,
    )
    items = response.data
  }

  return items
}

export const getCategoriesByLevel = async (level: number) => {
  const items = (await getCategories()).filter(
    (cat: any) => Number(cat.level) === level,
  )

  return items
}

export const getCategoryByPath = async (path: string) => {
  const category: any = (await getCategories()).filter(
    (cat: any) => cat.url_path === path,
  )[0]

  const token = await getMagentoBearerToken()
  const config = {
    headers: { Authorization: 'bearer ' + token },
  }

  if (!category) return []
  const cat = await getCategoryById(Number(category.id))

  return cat
}

export const getProduct = async (id: number) => {
  const token = await getMagentoBearerToken()
  const config = {
    headers: { Authorization: 'bearer ' + token },
  }
  const response = await axios.get(
    `${process.env.NEXT_PUBLIC_MAG_URL}/index.php/rest/V1/products/${id}`,
    config,
  )
  const product = response.data.items

  return product
}

// @TODO Depracated - use typesense.getTrendingProducts
export const getTrendingProducts = async () => {
  const token = await getMagentoBearerToken()

  const endpoint = 'https://www.foodservicedirect.com/graphql'
  const headers = {
    'content-type': 'application/json',
    Authorization: token,
  }
  const graphqlQuery = {
    query:
      '{\n                products : recommended_products(skus_query:"recsys=trending",limit:10){\n                 title : name \n                    sku\n                    uniqueId :sku\n                    image\n                    vendor_name\n                    isSpecialPriceAvailable:special_price\n                    special_price\n                    price :final_price\n                    ships_in\n                    productUrl :url\n                    liquidation\n                    liquidation_expiry_date\n                    new_arrivals\n                    new_arrivals_expiry_date\n                    shipping_type\n                    product_review{\n                      review_count\n                      rating_avg\n                  }\n                  vendor_id\n                  stock_type\n                  ships_in_days\n            }\n          }',
  }

  const response = await axios({
    url: endpoint,
    method: 'post',
    headers: headers,
    data: graphqlQuery,
  })

  return response.data.data.products
}

export const getAllBrandsOrManufacturers = async (type = 'manufacturers') => {
  const response = await axios.get(
    `${process.env.NEXT_PUBLIC_MAG_URL}rest/V1/get-brands-manufacturer-list`,
  )

  const brands = response.data[0]
  const manufacturer = response.data[1]

  const { items } = type === 'manufacturers' ? manufacturer : brands

  return items || []
}

export const getManufacturerData = async (manufacturerId = '') => {
  let response
  let errorResponse

  try {
    response = await axios.get(
      `${process.env.NEXT_PUBLIC_MAG_URL}rest/V1/get-all-manufacturers-info?id=${manufacturerId}`,
    )
  } catch (error) {
    if (error?.response?.status !== 200) {
      errorResponse = error
    }
  }

  return response?.data ? response.data[0] : { errorResponse }
}

export const getManufacturerBrandData = async (
  manufacturerId = '',
  brandId = '',
) => {
  let response
  let errorResponse

  try {
    response = await axios.get(
      `${process.env.NEXT_PUBLIC_MAG_URL}rest/V1/get-brands-info?brandId=${brandId}&manufacturerId=${manufacturerId}`,
    )
  } catch (error) {
    if (error?.response?.status !== 200) {
      errorResponse = error
    }
  }

  return response?.data ? response.data[0] : { errorResponse }
}

export const getFeaturedBrandsData = async () => {
  let response
  let errorResponse

  try {
    response = await axios.get(
      `${process.env.NEXT_PUBLIC_MAG_URL}rest/V1/get-featured-brands`,
    )
  } catch (error) {
    if (error?.response?.status !== 200) {
      errorResponse = error
    }
  }

  return response?.data ? response.data[0] : { errorResponse }
}

export const getAllManufacturersData = async () => {
  let response
  let errorResponse

  try {
    response = await axios.get(
      `${process.env.NEXT_PUBLIC_MAG_URL}rest/V1/get-all-manufacturers-info`,
    )
  } catch (error) {
    if (error?.response?.status !== 200) {
      errorResponse = error
    }
  }

  return response ? response : { errorResponse }
}

export const submitManufacturerContactFormData = async (data) => {
  let response
  let errorResponse

  try {
    response = await axios.post(
      `${process.env.NEXT_PUBLIC_MAG_URL}rest/V1/manufacturer-contact`,
      data,
    )
  } catch (error) {
    if (error?.response?.status !== 200) {
      errorResponse = error
    }
  }

  return response ? response : { errorResponse }
}
