import { useModalAction } from '@components/common/modal/modal.context'
import Button from '@components/ui/button'
import Input from '@components/ui/form/input'
import cn from 'classnames'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useForm } from 'react-hook-form'
import { FC, useContext, useState } from 'react'
import { UserContext } from '@contexts/user/user.context'
import { IUser } from '@contexts/user/user.utils'
import { toast } from 'react-toastify'
import saveListService from '@services/savelistservice'
import { useMutation } from 'react-query'

interface CreateList {
  listName: string
}

const CreateNewList: FC<any> = ({ data }) => {
  const { t: translate } = useTranslation(['common', 'savelist'])
  const { user } = useContext(UserContext) as IUser
  const router = useRouter()
  const { closeModal } = useModalAction()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateList>()

  const { mutate, isLoading } = useMutation(
    (payload: CreateList) => {
      return saveListService.createNewList(payload.listName, {
        customerId: user?.id,
      })
    },
    {
      onSuccess: (response: any) => {
        toast.success(response)
        data.fetchSavedLists(true)
        closeModal()
      },
      onError: (err: Error) => {
        toast.error(`Error submitting question: ${err.message}`, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'toast-error',
        })
      },
    },
  )

  function onSubmit(values: CreateList) {
    mutate({ ...values })
  }

  return (
    <div
      className={cn('w-full md:w-[500px] lg:w-[600px] xl:w-[650px]  relative')}
    >
      <div className="mx-auto overflow-hidden rounded-lg bg-brand-light p-5">
        <h1 className="text-brand-dark font-medium ">
          {translate('text-create-new-list', { ns: 'savelist' })}
        </h1>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Input
            variant="solid"
            placeholder={translate('text-new-list', { ns: 'savelist' })}
            className="my-6"
            {...register('listName', {
              required: translate('text-list-name-required', {
                ns: 'savelist',
              }),
            })}
            error={errors.listName?.message}
            maxLength={50}
          />

          <div className="text-right">
            <Button
              className="border-solid whitespace-nowrap px-2 mt-2 h-11 md:h-12 font-15px md:font-15px"
              variant="border"
              onClick={closeModal}
            >
              {translate('text-cancel', { ns: 'savelist' })}
            </Button>
            <Button
              className="border-solid whitespace-nowrap w-[120px] mt-2 ml-2 h-11 md:h-12 font-15px md:font-15px"
              variant="formButton"
              type="submit"
              loading={isLoading}
            >
              {translate('text-submit', { ns: 'savelist' })}
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CreateNewList
