import { CART_SERVICE } from '@constants/index'
import { callRestApi } from '@hooks/useRestApi'

const SERVICE_URLS = {
  guestEstimatedShippingMethods:
    '/guest-carts/{cart-id}/estimate-shipping-methods',
  customerEstimatedShippingMethods: '/carts/mine/estimate-shipping-methods',
  estimatedShippingMethodByAddressID:
    '/carts/mine/estimate-shipping-methods-by-address-id',
  guestShippingInformation: '/guest-carts/{cart-id}/shipping-information',
  customerShippingInformation: '/carts/mine/shipping-information',
  guestPaymentInformation: '/fsd-guest-carts/{cart-id}/payment-information',
  customerPaymentInformation: '/fsd-carts/mine/payment-information',
  guestBillingAddress: '/guest-carts/{cart-id}/billing-address',
  customerBillingAddress: '/carts/mine/billing-address',
  customerGroup: '/get-customer-group',
  customerOrder: '/fsd/customer/orders',
  updateAddress: `/fsdcustomers/addresses/update/`,
  addAddress: `/fsdcustomers/addresses/`,
  deleteAddress: `/fsdcustomers/addresses/`,
  forgetPassword: `/customers/password`,
  resetPassword: '/customers/resetPassword',
  changeCustomerPassword: '/customers/me/password',
  productInformation: (sku: number) => `/fsd/product-information/${sku}/simple`,
  vendorProducts: (vendorId: number) => `/fsd/vendor-products/${vendorId}`,
  returnFromPaypal: '/checkout/returnfrompaypal',
  guestSetAfterpayInformation: '/guest-carts/{cart-id}/set-payment-information',
  customerSetAfterpayInformation: '/carts/mine/set-payment-information',
  afterpayCheckout: '/afterpay/checkout',
  guestAfterpayPlaceOrder: '/fsdafterpay/guest/placeorder',
  customerAfterpayPlaceOrder: '/fsdafterpay/express/placeorder',
  guestCouponCode: '/guest-carts/{cart-id}/coupons/',
  customerCouponCode: '/carts/mine/coupons/',
  // Referral Rewards apis
  referralRewardsUrl: '/awRafAdvocate/getReferralCode',
  referralRewardsSubscribe: '/awRafAdvocate/updateNewRewardSubscriptionStatus',
  referralRewardsInfo: '/awRafAdvocate/getCustomerRafInfo',
  checkReferralUrl: '/awRafAdvocate/checkReferralUrl',
  // newsletter
  newsletterSubscribe: '/newsletter/subscriber/email',
  getCustomQuote: '/create/pallet/request',
  orderAdditionalInformation: '/order/additional-information',
  deleteCard: '/braintree/deletecreditcard',
  applySc: '/carts/mine/balance/apply',
  removeSc: '/fsd/store-credit/remove',
  validateUpsAddress: '/ups/shipping/address/validate',
  verifyUpsAddress: '/ups/address/validate',
  addWishlistItem: '/wishlist/additem',
  getWishlistItems: '/wishlist/list',
  removeWishlistItem: '/wishlist/remove/',
  addToCartWishListItem: '/wishlist/addtocart',
  guestCartIdBuyNow: (id: number) => `/fsd/guest-carts/${id}`,
  guestBuyNow: '/fsd/guest/buynow',
  customerBuyNow: '/fsd/mine/buynow',
  currentSession: '/fsd/customers/current-session',
  checkoutBuyNow: (id: number) => `/fsd/guest-carts/${id}/checkout`,
  customerCheckoutBuyNow: '/fsd/carts/mine/checkout',
  taxExemptionFileUpload: '/fsd/customer/file_upload',
}

const estimatedShippingMethods = async (isGuest: boolean, data?: any) => {
  const res: any = await callRestApi(
    isGuest
      ? SERVICE_URLS.guestEstimatedShippingMethods
      : SERVICE_URLS.customerEstimatedShippingMethods,
    'post',
    data,
  )
  return res
}

const estimatedShippingMethodsByAddressId = async (data?: any) => {
  const res: any = await callRestApi(
    SERVICE_URLS.estimatedShippingMethodByAddressID,
    'post',
    data,
  )
  return res
}

const updateAddressApi = async (id?: string, data?: any) => {
  const res: any = await callRestApi(
    SERVICE_URLS.updateAddress + id,
    'put',
    data,
  )
  return res
}

const addAddressApi = async (userid?: string, data?: any) => {
  const res: any = await callRestApi(
    SERVICE_URLS.addAddress + userid,
    'post',
    data,
  )
  return res
}
const deleteAddressApi = async (data?: any) => {
  const res: any = await callRestApi(
    SERVICE_URLS.deleteAddress + data,
    'delete',
    null,
  )
  return res
}

const forgetPasswordApi = async (data?: any) => {
  const res: any = await callRestApi(SERVICE_URLS.forgetPassword, 'put', data)
  return res
}

const getCustomQuoteApi = async (data?: any) => {
  const res: any = await callRestApi(SERVICE_URLS.getCustomQuote, 'post', data)
  return res
}

const resetPassword = async (data?: any) => {
  const res: any = await callRestApi(SERVICE_URLS.resetPassword, 'post', data)
  return res
}

const shippingAddressFromQuote = async (isGuest: boolean, data?: any) => {
  return callRestApi(
    isGuest
      ? SERVICE_URLS.guestShippingInformation
      : SERVICE_URLS.customerShippingInformation,
    'post',
    data,
  )
}

const orderPlaced = async (isGuest: boolean, data: any) => {
  return callRestApi(
    isGuest
      ? SERVICE_URLS.guestPaymentInformation
      : SERVICE_URLS.customerPaymentInformation,
    'post',
    data,
  )
}

const getBillingAddress = (isGuest: boolean) => {
  return callRestApi(
    isGuest
      ? SERVICE_URLS.guestBillingAddress
      : SERVICE_URLS.customerBillingAddress,
  )
}

const setSelectedPostalCode = (value: string) => {
  let { POSTAL_CODE } = CART_SERVICE
  if (typeof window !== 'undefined') {
    if (
      !localStorage.getItem(POSTAL_CODE) ||
      value !== localStorage.getItem(POSTAL_CODE)
    ) {
      localStorage.setItem(POSTAL_CODE, value)
    }
  }
}

const getCustomerGroup = () => {
  return callRestApi(SERVICE_URLS.customerGroup)
}

const getCustomerOrder = () => {
  return callRestApi(SERVICE_URLS.customerOrder)
}

const getVendorsProducts = (vendorId: number, data: any) => {
  return callRestApi(SERVICE_URLS.vendorProducts(vendorId), 'post', data)
}

const setPaymentInformation = (isGuest: boolean, data: any) => {
  return callRestApi(
    isGuest
      ? SERVICE_URLS.guestSetAfterpayInformation
      : SERVICE_URLS.customerSetAfterpayInformation,
    'post',
    data,
  )
}

const getAfterpayCheckoutUrl = (data: any) => {
  return callRestApi(SERVICE_URLS.afterpayCheckout, 'post', data)
}

const changeCustomerPassword = (data?: any) => {
  return callRestApi(SERVICE_URLS.changeCustomerPassword, 'put', data)
}

const getSelectedPostalCode = () => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem(CART_SERVICE.POSTAL_CODE)
  }
}

const createShippingInfoPayload = ({
  shippingInformation,
  billingInformation,
  firstMultipleShippingMethod,
  sameAsShipping,
  user,
  isOnlyPickUp,
}: any) => {
  const payload: any = {
    addressInformation: {
      shippingAddress: {
        country_id: shippingInformation.country_id,
        postcode: shippingInformation.postcode,
        saveInAddressBook: sameAsShipping ? 0 : shippingInformation?.id ? 0 : 1,
        region: shippingInformation?.region_code
          ? shippingInformation?.region_code
          : shippingInformation?.region,
        firstname: shippingInformation?.firstname,
        lastname: shippingInformation?.lastname,
        city: shippingInformation?.city,
        region_code: shippingInformation?.region_code,
        street: Array.isArray(shippingInformation?.street)
          ? shippingInformation?.street
          : [shippingInformation?.street],
        telephone: shippingInformation?.telephone,
        extension_attributes: {
          address_type: shippingInformation?.extension_attributes?.address_type,
        },
      },
      billingAddress: {
        country_id: billingInformation.country_id,
        postcode: billingInformation.postcode,
        saveInAddressBook: billingInformation?.id ? 0 : 1,
        region_code: billingInformation?.region_code,
        firstname: billingInformation?.firstname,
        lastname: billingInformation?.lastname,
        city: billingInformation?.city,
        street: Array.isArray(billingInformation?.street)
          ? billingInformation?.street
          : [billingInformation?.street],
        telephone: billingInformation?.telephone,
        extension_attributes: {
          address_type: billingInformation?.extension_attributes?.address_type,
        },
      },
      shippingCarrierCode: firstMultipleShippingMethod?.carrier_code,
      shippingMethodCode: firstMultipleShippingMethod?.method_code,
    },
  }
  if (isOnlyPickUp) {
    payload.addressInformation.shippingAddress.saveInAddressBook = 0
  }
  if (user) {
    payload.addressInformation.shippingAddress.email = user.email
    payload.addressInformation.billingAddress.email = user.email
  }
  if (sameAsShipping)
    payload.addressInformation.billingAddress =
      payload.addressInformation.shippingAddress

  return payload
}

const getProductInformation = (sku: number) => {
  return callRestApi(SERVICE_URLS.productInformation(sku))
}

const getPaypalAuth = (data: any) => {
  return callRestApi(SERVICE_URLS.returnFromPaypal, 'post', data)
}

const afterpayPlaceOrder = (isGuest: boolean, data: any) => {
  return callRestApi(
    isGuest
      ? SERVICE_URLS.guestAfterpayPlaceOrder
      : SERVICE_URLS.customerAfterpayPlaceOrder,
    'post',
    data,
  )
}

const addCouponCode = (couponCode: string, isGuest: boolean, data: any) => {
  return callRestApi(
    isGuest
      ? SERVICE_URLS.guestCouponCode + couponCode
      : SERVICE_URLS.customerCouponCode + couponCode,
    'put',
    data,
  )
}

const removeCouponCode = (isGuest: boolean) => {
  return callRestApi(
    isGuest ? SERVICE_URLS.guestCouponCode : SERVICE_URLS.customerCouponCode,
    'delete',
  )
}

const getReferralRewardsUrl = (customerId: string, websiteId: string) => {
  return callRestApi(
    `${SERVICE_URLS.referralRewardsUrl}?customerId=${customerId}&websiteId=${websiteId}`,
    'get',
  )
}

const subscribeReferralRewards = (data: any) => {
  return callRestApi(SERVICE_URLS.referralRewardsSubscribe, 'put', data)
}

const getReferralRewardsInfo = (data: any) => {
  return callRestApi(SERVICE_URLS.referralRewardsInfo, 'post', data)
}

const checkReferralRewardsUrl = (
  referralToken: string | string[],
  websiteId: string | undefined,
) => {
  return callRestApi(
    `${SERVICE_URLS.checkReferralUrl}?referralToken=${referralToken}&websiteId=${websiteId}&welcomePopup=true`,
    'get',
  )
}

const subscribeNewsLetter = (data: any) => {
  return callRestApi(SERVICE_URLS.newsletterSubscribe, 'post', data)
}

const orderAdditionalInfo = (data: any) => {
  return callRestApi(SERVICE_URLS.orderAdditionalInformation, 'post', data)
}

const deleteCardApi = (data: any) => {
  return callRestApi(SERVICE_URLS.deleteCard, 'post', data)
}

const applyStoreCreditApi = () => {
  return callRestApi(SERVICE_URLS.applySc, 'post')
}

const removeStoreCreditApi = () => {
  return callRestApi(SERVICE_URLS.removeSc, 'delete')
}

const validateAddress = (data: any) => {
  return callRestApi(SERVICE_URLS.validateUpsAddress, 'post', data)
}

const verifyUpsAddress = (data: any) => {
  return callRestApi(SERVICE_URLS.verifyUpsAddress, 'post', data)
}

// wishlist

const addItemInWishlist = (data: any) => {
  return callRestApi(SERVICE_URLS.addWishlistItem, 'post', data)
}

const getWishlistProducts = () => {
  return callRestApi(SERVICE_URLS.getWishlistItems)
}

const removeWishlistItem = (id: number) => {
  return callRestApi(SERVICE_URLS.removeWishlistItem + id, 'put')
}

const addToCartWishlistItem = (data: any) => {
  return callRestApi(SERVICE_URLS.addToCartWishListItem, 'post', data)
}

const guestCartIdBuyNow = (id: any) => {
  return callRestApi(SERVICE_URLS.guestCartIdBuyNow(id), 'get')
}

const guestBuyNow = (data: any) => {
  return callRestApi(SERVICE_URLS.guestBuyNow, 'post', data)
}

const customerBuyNow = (data: any) => {
  return callRestApi(SERVICE_URLS.customerBuyNow, 'post', data)
}

const currentSession = () => {
  return callRestApi(SERVICE_URLS.currentSession)
}

const checkoutBuyNow = (id: any) => {
  return callRestApi(SERVICE_URLS.checkoutBuyNow(id), 'get')
}

const customerCheckoutBuyNow = () => {
  return callRestApi(SERVICE_URLS.customerCheckoutBuyNow)
}

const taxExemptionFileUpload = (data: any) => {
  return callRestApi(SERVICE_URLS.taxExemptionFileUpload, 'post', data)
}

const cartServices = {
  estimatedShippingMethods,
  estimatedShippingMethodsByAddressId,
  shippingAddressFromQuote,
  getBillingAddress,
  orderPlaced,
  setSelectedPostalCode,
  getSelectedPostalCode,
  createShippingInfoPayload,
  getCustomerGroup,
  getCustomerOrder,
  updateAddressApi,
  addAddressApi,
  deleteAddressApi,
  forgetPasswordApi,
  changeCustomerPassword,
  getProductInformation,
  getVendorsProducts,
  getPaypalAuth,
  setPaymentInformation,
  getAfterpayCheckoutUrl,
  afterpayPlaceOrder,
  addCouponCode,
  removeCouponCode,
  resetPassword,
  getReferralRewardsUrl,
  getReferralRewardsInfo,
  subscribeReferralRewards,
  checkReferralRewardsUrl,
  subscribeNewsLetter,
  getCustomQuoteApi,
  orderAdditionalInfo,
  deleteCardApi,
  applyStoreCreditApi,
  removeStoreCreditApi,
  validateAddress,
  verifyUpsAddress,
  addItemInWishlist,
  getWishlistProducts,
  removeWishlistItem,
  addToCartWishlistItem,
  guestCartIdBuyNow,
  guestBuyNow,
  customerBuyNow,
  currentSession,
  checkoutBuyNow,
  customerCheckoutBuyNow,
  taxExemptionFileUpload,
}

export default cartServices
