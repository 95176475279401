import client from '@utils/graphql'
import gql from 'graphql-tag'

const getProductData = async (sku: string) => {
  const { data: productData } = await client.mutate({
    mutation: gql`
      query GetItem($sku: String!) {
        products: product_details(skus_comma_separated: $sku) {
          sku
          vendor_id
          image
          name
          special_price
          price
          url
          ships_in
          shipping_type
          is_in_stock
          qty
          liquidation
          liquidation_expiry_date
          new_arrivals
          new_arrivals_expiry_date
          rebate_eligibility
          mp_price
          mp_special_price
          mp_special_from_date
          mp_special_to_date
          product_review {
            review_count
            rating_avg
          }
          sold_as
          ships_in_days
          stock_type
          min_sale_qty
          max_sale_qty
        }
      }
    `,
    variables: {
      sku,
    },
  })

  return productData
}

export default getProductData
