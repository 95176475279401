import { useCart } from '@contexts/cart/cart.context'
import { IUser } from '@contexts/user/user.utils'

import { UserContext } from '@contexts/user/user.context'
import { useLocalStorage } from '@utils/use-local-storage'
import cn from 'classnames'
import { useSession } from 'next-auth/react'
import {
  ComponentType,
  FC,
  HTMLAttributes,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react'
import { updateSessionStore } from '@utils/helper'
interface Props {
  className?: string
  children?: any
  el?: HTMLElement
  clean?: boolean
}

const Container: FC<Props> = ({ children, className, el = 'div', clean }) => {
  const { resetCart, logoutResetCart, logoutBuyNowResetCart } = useCart()
  const [localCartError, , removeLocalCartError] = useLocalStorage('cart-error')
  const [localLoggedOut, , removeLocalLoggedOut] = useLocalStorage('logged-out')

  const { data: session } = useSession()

  const { setCreateCart } = useContext(UserContext) as IUser

  useLayoutEffect(() => {
    if (session === null && localLoggedOut) {
      const hasTemporaryToken = localStorage.getItem('temporaryToken')

      if (hasTemporaryToken) {
        logoutBuyNowResetCart()
        localStorage.removeItem('temporaryToken')
      } else {
        logoutResetCart()
      }

      setCreateCart(true)
      removeLocalLoggedOut()
    } else {
      updateSessionStore(session)
    }
  }, [session])

  useEffect(() => {
    if (localCartError) {
      resetCart()
      removeLocalCartError()
      localStorage.clear()
    }
  }, [localCartError])

  const rootClassName = cn(className, {
    'mx-auto max-w-[1920px] px-0 sm:px-4 md:px-6 lg:px-8 2xl:px-10': !clean,
  })

  let Component: ComponentType<HTMLAttributes<HTMLDivElement>> = el as any

  return <Component className={rootClassName}>{children}</Component>
}

export default Container
