export const StarRating = ({ rating }) => (
  <div className="flex">
    {[...Array(rating)].map((_, i) => (
      <svg
        key={i}
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clip-rule="evenodd"
          d="M11.6646 7.12771L9.5 0L7.33536 7.12771H0L5.93479 11.742L3.73214 19L9.5 14.5146L15.2679 19L13.0652 11.742L19 7.12771H11.6646Z"
          fill="#FCA120"
        />
      </svg>
    ))}
  </div>
)
