import { UserContext } from '@contexts/user/user.context'
import { Address, IUser } from '@contexts/user/user.utils'
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  CheckoutSteps,
  Config,
  ContactFormValues,
  CreditCardFormValues,
  IsNewAddress,
  ShippingFormValues,
} from './checkout.utils'
import checkoutServices from '@services/checkoutservices'
import { PaymentMethod } from 'src/interfaces/checkout'
import { callRestApi } from '@hooks/useRestApi'
import ENDPOINT from '@utils/restendpoints'
import { BraintreeCard } from '@components/checkout/credit-card-select-list'
import { FREE_SHIPPING } from '@constants/index'
import usePrice from '@framework/product/use-price'

export interface ICheckout {
  sameAsShipping: boolean
  setSameAsShipping: Dispatch<SetStateAction<boolean>>
  contactInfo: ContactFormValues
  setContactInfo: Dispatch<SetStateAction<ContactFormValues>>
  billingInfo: ShippingFormValues
  setBillingInfo: Dispatch<SetStateAction<ShippingFormValues>>
  shippingInfo: ShippingFormValues
  setShippingInfo: Dispatch<SetStateAction<ShippingFormValues>>
  paymentInfo: CreditCardFormValues
  setPaymentInfo: Dispatch<SetStateAction<CreditCardFormValues>>
  formErrors: { billing: FormErrors; shipping; creditcard: cCFormErrors }
  setFormErrors: Dispatch<SetStateAction<{ billing: FormErrors; shipping }>>
  setCurrentPaymentMethod: any
  currentPaymentMethod: any
  paypalNonce: string
  setPaypalNonce: any
  resetFormStates: any
  addressform: boolean
  setAddressForm: Dispatch<SetStateAction<boolean>>
  billingAddressform: boolean
  setBillingAddressForm: Dispatch<SetStateAction<boolean>>
  isNewAddress: IsNewAddress
  setIsNewAddress: Dispatch<SetStateAction<IsNewAddress>>
  newCreditCard: boolean
  setNewCreditCard: Dispatch<SetStateAction<boolean>>
  selectedCcExpiryDate: string
  setSelectedCcExpiryDate: Dispatch<SetStateAction<string>>
  currentBraintreeCard: BraintreeCard | null
  selectCurrentBraintreeCard: Dispatch<SetStateAction<BraintreeCard | null>>
  applePayNonce: string
  setApplePayNonce: any
  hasStoreCreditApplied: any
  setHasStoreCreditApplied: any
  upsAddressType: any
  setUpsAddressType: any
  placingOrder: boolean
  setPlacingOrder: Dispatch<SetStateAction<boolean>>
  disableOrderNowButton: boolean
  setDisableOrderNowButton: Dispatch<SetStateAction<boolean>>
  disabledPlaceOrder: boolean
  magConfig: Partial<Config>
  setMagConfig: Dispatch<SetStateAction<Partial<Config>>>
  checkoutLogin: boolean
  setCheckoutLogin: Dispatch<SetStateAction<boolean>>
  calculatedPrice: (amount: number) => string
  checkoutFormStep: CheckoutSteps
  setCheckoutFormStep: Dispatch<SetStateAction<CheckoutSteps>>
  selectedAddresses: selectedAddresses
  setSelectedAdresses: Dispatch<SetStateAction<selectedAddresses>>
  isSubscribedExcitingOffers: boolean
  setIsSubscribedExcitingOffers: Dispatch<SetStateAction<boolean>>
  savelist: any
  setSavelist: any
  resetAddressFormErrors: () => void
}
export interface FormErrors {
  email: string
  zipCode: string
  name: string
  lname: string
  bus: string
  phone: string
  address1: string
  address2: string
  city: string
  zip: string
  region: string
  customerType: string
}

export interface cCFormErrors {
  cardName: string
  cardNumber: string
  expDate: string
  cvv: string
}

interface selectedAddresses {
  shipping: Address
  billing: Address
}

export const checkoutContext = createContext<ICheckout | undefined>(undefined)

export const useCheckout = () => {
  const context = useContext(checkoutContext)
  if (context === undefined) {
    throw new Error(`useCheckout must be used within a CheckoutProvider`)
  }
  return context
}

export const emptyAddress = {
  email: '',
  firstName: '',
  lastName: '',
  companyName: '',
  phoneNumber: '',
  addressLineOne: '',
  addressLineTwo: '',
  city: '',
  zipCode: '',
  deliveryInstruction: '',
  region: '',
  region_code: '',
  region_id: '',
}
const emptyErrors = {
  email: '',
  zipCode: '',
  name: '',
  lname: '',
  bus: '',
  phone: '',
  address1: '',
  address2: '',
  city: '',
  zip: '',
  region: '',
  cardName: '',
  cardNumber: '',
  expDate: '',
  cvv: '',
  customerType: '',
}
const emptyCcErrors = {
  cardName: '',
  cardNumber: '',
  expDate: '',
  cvv: '',
}

const defaultIsNewAddress = {
  shipping: false,
  billing: false,
  isEditAddress: false,
}

export function CheckoutProvider(props: PropsWithChildren<any>) {
  const [contactInfo, setContactInfo] = useState<ContactFormValues>({
    email: '',
    customerType: '',
  })
  const [checkoutLogin, setCheckoutLogin] = useState(false)
  const { user, cart, sameAsShipping, setSameAsShipping } = useContext(
    UserContext,
  ) as IUser

  const [paymentType, setPaymentType] = useState('')
  const [addressform, setAddressForm] = useState(true)
  const [billingAddressform, setBillingAddressForm] = useState(false)
  const [isNewAddress, setIsNewAddress] = useState(defaultIsNewAddress)
  const [newCreditCard, setNewCreditCard] = useState(true)
  const [selectedCcExpiryDate, setSelectedCcExpiryDate] = useState('')
  const [placingOrder, setPlacingOrder] = useState(false)
  const [disableOrderNowButton, setDisableOrderNowButton] = useState(false)
  const [isSubscribedExcitingOffers, setIsSubscribedExcitingOffers] =
    useState(false)
  const [selectedAddresses, setSelectedAdresses] = useState<selectedAddresses>({
    shipping: null,
    billing: null,
  })
  const [checkoutFormStep, setCheckoutFormStep] = useState(
    CheckoutSteps.SHIPPING_ADDRESS,
  )
  const [formErrors, setFormErrors] = useState<{
    shipping: FormErrors
    billing: FormErrors
    creditcard: cCFormErrors
  }>({
    shipping: emptyErrors,
    billing: emptyErrors,
    creditcard: emptyCcErrors,
  })

  const resetAddressFormErrors = () => {
    setFormErrors({
      shipping: emptyErrors,
      billing: emptyErrors,
      creditcard: emptyCcErrors,
    })
    setSameAsShipping(true)
    setIsNewAddress(defaultIsNewAddress)
    setAddressForm(true)
    setBillingAddressForm(false)
  }

  const [shippingInfo, setShippingInfo] =
    useState<ShippingFormValues>(emptyAddress)

  const [billingInfo, setBillingInfo] =
    useState<ShippingFormValues>(emptyAddress)

  const [paymentInfo, setPaymentInfo] = useState<CreditCardFormValues>({
    name: '',
    number: '',
    expirationDate: '',
    cvv: '',
  })

  const [currentPaymentMethod, setCurrentPaymentMethod] = useState<any>({})
  const [paypalNonce, setPaypalNonce] = useState('')
  const [applePayNonce, setApplePayNonce] = useState('')
  const [hasStoreCreditApplied, setHasStoreCreditApplied] = useState<any[]>([])
  const [upsAddressType, setUpsAddressType] = useState(null)
  const [disabledPlaceOrder, setDisabledPlaceOrder] = useState(false)
  const [magConfig, setMagConfig] = useState<Partial<Config>>({})
  const [currentBraintreeCard, selectCurrentBraintreeCard] =
    useState<BraintreeCard | null>(null)
  const [savelist, setSavelist] = useState<any[]>([])

  useEffect(() => {
    const callMagConfig = async () => {
      let magConfigResponse: any = await callRestApi(ENDPOINT.MAG_CONFIG)
      setMagConfig(magConfigResponse)
    }
    callMagConfig()
  }, [])

  const resetFormStates = () => {
    setContactInfo({ email: '', customerType: '' })
    setSameAsShipping(true)
    setPaymentType('')
    setFormErrors({
      shipping: emptyErrors,
      billing: emptyErrors,
      creditcard: emptyCcErrors,
    })
    setShippingInfo(emptyAddress)
    setBillingInfo(emptyAddress)
    setPaymentInfo({
      name: '',
      number: '',
      expirationDate: '',
      cvv: '',
    })
    setIsNewAddress(defaultIsNewAddress)
    setCurrentPaymentMethod({})
    setPaypalNonce('')
    setApplePayNonce('')
    setNewCreditCard(true)
    setHasStoreCreditApplied([])
    setUpsAddressType(null)
    setSelectedAdresses({ shipping: null, billing: null })
  }

  const calculatedPrice = (amount: number) => {
    return amount === 0
      ? FREE_SHIPPING
      : usePrice({
          amount: amount,
          currencyCode: process.env.NEXT_PUBLIC_CURRENCY_CODE,
        }).price
  }

  useEffect(() => {
    if (cart?.totals?.total_segments) {
      const storeCreditApplied = cart.totals.total_segments.filter(
        (item: { code: string }) => item?.code === 'customerbalance',
      )
      setHasStoreCreditApplied(storeCreditApplied)
    }
  }, [cart])

  useEffect(() => {
    setDisabledPlaceOrder(
      checkoutServices.disablePlaceOrderButton(
        currentPaymentMethod,
        paypalNonce,
        applePayNonce,
      ),
    )
  }, [currentPaymentMethod, paypalNonce, applePayNonce])

  return (
    <checkoutContext.Provider
      value={{
        sameAsShipping,
        setSameAsShipping,
        setContactInfo,
        contactInfo,
        billingInfo,
        setBillingInfo,
        shippingInfo,
        setShippingInfo,
        setPaymentInfo,
        paymentInfo,
        formErrors,
        setFormErrors,
        setCurrentPaymentMethod,
        currentPaymentMethod,
        paypalNonce,
        setPaypalNonce,
        resetFormStates,
        addressform,
        setAddressForm,
        billingAddressform,
        setBillingAddressForm,
        isNewAddress,
        setIsNewAddress,
        newCreditCard,
        setNewCreditCard,
        selectedCcExpiryDate,
        setSelectedCcExpiryDate,
        applePayNonce,
        setApplePayNonce,
        hasStoreCreditApplied,
        setHasStoreCreditApplied,
        upsAddressType,
        setUpsAddressType,
        placingOrder,
        setPlacingOrder,
        disableOrderNowButton,
        setDisableOrderNowButton,
        disabledPlaceOrder,
        magConfig,
        setMagConfig,
        currentBraintreeCard,
        selectCurrentBraintreeCard,
        checkoutLogin,
        setCheckoutLogin,
        calculatedPrice,
        checkoutFormStep,
        setCheckoutFormStep,
        selectedAddresses,
        setSelectedAdresses,
        isSubscribedExcitingOffers,
        setIsSubscribedExcitingOffers,
        savelist,
        setSavelist,
        resetAddressFormErrors,
      }}
    >
      {props.children}
    </checkoutContext.Provider>
  )
}
