import { ImSpinner2 } from 'react-icons/im'
import cn from 'classnames'
import { ButtonHTMLAttributes } from 'react'

interface OutlineButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
  variant?: 'primary' | 'border' | 'formButton'
  active?: boolean
  type?: 'submit' | 'reset' | 'button'
  loading?: boolean | string
  disabled?: boolean
  isSelected?: boolean
}

const OutlineButton = ({
  children,
  className,
  disabled,
  isSelected,
  loading,
  ...rest
}: OutlineButtonProps) => {
  const rootClassName = cn(
    'font-semibold border-1 text-sm border-brand-alphagray border-solid text-brand hover:opacity-80 inline-flex justify-center items-center',
    {
      'border-brand-darkblue': isSelected,
    },
    className,
  )
  return (
    <button className={rootClassName} {...rest}>
      {children}
      {loading && (
        <ImSpinner2 className="w-5 h-5 animate-spin ltr:-mr-1 rtl:-ml-1  rtl:mr-3 " />
      )}
    </button>
  )
}

export default OutlineButton
