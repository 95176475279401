export const SESSION_STATUS = Object.freeze({
  AUTH: 'authenticated',
  UNAUTH: 'unauthenticated',
})

export const CART_STATUS = Object.freeze({
  EMPTY: 'EMPTY_CART',
  ADDED: 'ADDED_ITEM',
  YOUR_CART: 'YOUR_CART',
})

export const CART_SERVICE = Object.freeze({
  POSTAL_CODE: 'selectedPostalCode',
})

export const COUNTRY_ID = process.env.NEXT_PUBLIC_WEBSITE_COUNTRY || 'US'
export const CURRENCY_CODE = process.env.NEXT_PUBLIC_CURRENCY_CODE || 'USD'

const ZIP_CODE_MASKS = { US: '99999', CA: '' }
export const ZIP_CODE_MASK = ZIP_CODE_MASKS[COUNTRY_ID]

const DEFAULT_CODES = { US: '12311', CA: 'R2M 0T7' }
export const DEFAULT_CODE = DEFAULT_CODES[COUNTRY_ID]

const SETTINGS = {
  shipping: {
    DEFAULT_POST_CODE: '',
    DEFAULT_REGION: '',
    DEFAULT_COUNTRY_ID: COUNTRY_ID,
    ADDRESS_TYPE: 'bus',
  },
}

export const PAYMENT_METHOD = Object.freeze({
  FREE: 'free',
  BRAINTREE_CREDITCARD: 'braintree',
  BRAINTREE_SAVEDCARD: 'braintree_cc_vault',
  BRAINTREE_PAYPAL: 'braintree_paypal',
  AFTERPAY: 'afterpay',
  BRAINTREE_APPLEPAY: 'braintree_applepay',
  TREVIPAY: 'trevipay_magento',
  CREDIT_CARD: 'Credit Card',
  STORE_CREDIT: 'No Payment Information Required',
  STORED_CARDS: 'Stored Cards (Braintree)',
  INVOICE_TERMS: '30-Day Invoice Terms',
  TREVIPAY_TITLE: 'Trevipay',
})

export const STOCK_TYPE = Object.freeze({
  STOCKED: 'Stocked',
  ON_REQUEST: 'On Request',
  SPECIAL_ORDER: 'Special Order',
})

export const SHIPPING_TYPE = Object.freeze({
  SHIPS_FREE: 'Ships Free',
  INCLUDED_IN_THRESHOLD: 'Included in Threshold',
  NEVER_SHIPS_FREE: 'Never ships Free',
})

export const FLAGS = Object.freeze({
  REBATE_ELIGIBLE: 'rebate_eligible',
  SPECIAL_ORDER: 'special_order',
})

export const PRODUCT_TABS = Object.freeze({
  PRODUCT_SPECIFICATIONS: 'Product Specifications',
  NUTRITION_FACTS: 'Nutrition Facts',
  FOOD_PROPERTIES: 'Food Properties',
  ALLERGENS: {
    FREE_FROM: 'Free From',
  },
})

export const CUSTOMER_GROUPS = Object.freeze({
  NOT_LOGGED_IN: 'NOT LOGGED IN',
  SELECT: 'Select',
})

export const CARRIER_CODE_UPS = 'ups'
export const CARRIER_CODE_PUROLATOR = 'purolator'
export const CARRIER_CODE_OMS = 'omsshipping'
export const CARRIER_CODE_RBMATRIX = 'rbmatrixrate'
export const FREE_SHIPPING = 'Free Shipping'
export const CANADIAN_POSTAL_CODE_REGEX =
  /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/

export const US_POSTAL_CODE_REGEX = /^\d{5}(-\d{4})?$/

const POSTAL_CODE_REGEXES = {
  US: US_POSTAL_CODE_REGEX,
  CA: CANADIAN_POSTAL_CODE_REGEX,
}
export const POSTAL_CODE_REGEX = POSTAL_CODE_REGEXES[COUNTRY_ID]

export const COUNTRY_CANADA = 'CA'
export const COUNTRY_US = 'US'

export const EMAIL_REGEX =
  /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/

export const PHONE_NUMBER_MASK = '+19999999999'
export const PICK_UP = 'pickup'
export const DELIVERY = 'delivery'
export const SHIPPING = 'shipping'
export const BILLING = 'billing'
export const CREDITCARD = 'creditcard'
export const LOCAL_INV_FLAG = 'local_inv'
export const CART_SIDEBAR = 'CART_SIDEBAR'
export const REFRIGERATED = 'Refrigerated'
export const FROZEN = 'Frozen'
export const FROZEN_SHIPPING = 'Frozen Shipping'
export const BUYNOW_TEMPORARY_TOKEN = 'temporaryToken'
export const SHIPS_FREE = 'Ships Free'
export const ITEM_ADDTOCART_MAX_LIMIT = 2000
export const ITEM_DOESNOT_EXIST_ERROR =
  "The product that was requested doesn't exist. Verify the product and try again."
export const CONFIGURABLE_PRODUCT_TYPE = 'configurable'
export const STORE_CREDIT_TEXT = 'Store Credit'
export const REVIEWS_SECTION = 'reviews-section'

export const frenchTranslations: any = {
  Bakery: 'Boulangerie',
  'Bar / Club / Entertainment': 'Bar / Club / Divertissement',
  'Business Office': "Bureau d'entreprise",
  Butcher: 'Boucher',
  Caterer: 'Traiteur',
  'Coffee Shop': 'Café',
  'Convenience Store': 'Dépanneur',
  Deli: 'Deli',
  'Doctor’s Offices': 'Cabinet Médical',
  'Event Planner': "Organisateur d'Événements",
  'Food Truck': 'Food truck',
  'Gas Station': 'Station-service',
  'Municipality / Government': 'Municipalité / Gouvernement',
  'Grocery / Retail Store': 'Épicerie / Commerce de détail',
  'Home Use (Consumer)': 'Usage Domestique (Consommateur)',
  'Hospital / Clinic': 'Hôpital / Clinique',
  'Hotel / B&B': 'Hôtel',
  Manufacturer: 'Manufacturier',
  Military: 'Armée',
  'Recreational Centre': 'Centre de loisirs',
  'Religious Location': 'Lieu de culte',
  'Restaurant – (Chain)': 'Restaurant - (Chaîne)',
  'Restaurant – (Independent)': 'Restaurant - (Indépendant)',
  Retailer: 'Détaillant',
  'Retirement / Nursing Home': 'Maison de retraite / Maison de soins',
  'School / Day Care': 'École / Garderie',
  Wholesale: 'Vente en gros',
  'Winery / Tasting Bar': 'Vignoble / Bar de dégustation',
  'Other Business': 'Autres activités',
}

export default SETTINGS
