import Input from '@components/ui/form/input'
import { FC, useEffect, useState, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { QuickEntryItem } from './savelist.utils'
import usePrice from '@framework/product/use-price'
import { debounce } from 'lodash' // Import lodash debounce
import getProductData from '@framework/savelist/get-item-graphql'
import { createPricePayload, getCurrentPrice } from '@utils/helper'
import { SaveListPopupTableProps } from './savelist.utils'
import Counter from '@components/ui/counter'

const SaveListPopupTable: FC<SaveListPopupTableProps> = ({
  quickEntryList,
  updateQuickEntryItem,
}) => {
  const [items, setItems] = useState<QuickEntryItem[]>([])
  const { t: translate } = useTranslation('savelist')

  useEffect(() => setItems([...quickEntryList]), [quickEntryList])

  const { register } = useForm<any>()

  const isSkuExists = (
    item: QuickEntryItem,
    currentItems: QuickEntryItem[],
  ): boolean => {
    return currentItems.some(
      (list, index) =>
        item.sku.length > 0 && item.index !== index && list.sku === item.sku,
    )
  }

  const priceChangeHandler = async (
    item: QuickEntryItem,
    currentItems: QuickEntryItem[],
  ) => {
    item.alreadyExists = isSkuExists(item, currentItems)
    if (!item.sku.length) item.doesNotExists = false
    let { products } = await getProductData(item.sku)
    if (products[0]?.price) {
      item.doesNotExists = false
      ;(item.price = getCurrentPrice(createPricePayload(products[0]))),
        (item.min_sale_qty = products[0]?.min_sale_qty)
      item.max_sale_qty = products[0]?.max_sale_qty
      if (products[0]?.min_sale_qty > 1) {
        item.qty = products[0].min_sale_qty
        item.min_sale_qty = products[0].min_sale_qty
      }
      updateQuickEntryItem(item, item.index)
    } else {
      if (item.sku.length) item.doesNotExists = true
      item.price = 0.0
      item.qty = 1
      item.min_sale_qty = 1
      item.max_sale_qty = 999
      updateQuickEntryItem(item, item.index)
    }
  }

  const debouncedLog = useMemo(
    () =>
      debounce((value: QuickEntryItem, currentItems: QuickEntryItem[]) => {
        priceChangeHandler(value, currentItems)
      }, 250),
    [],
  )

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    updateQuickEntryItem(
      { ...quickEntryList[index], sku: e.target.value }, // Update sku only
      index,
    )
    // Call the debounced function with the updated item
    debouncedLog(
      { ...quickEntryList[index], sku: e.target.value, index },
      items,
    )
  }

  const onQtyChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    index,
  ) => {
    let numberFlag = isNaN(parseInt(event.target.value))
    let newValue = numberFlag ? 1 : parseInt(event.target.value)
    if (newValue < 1) return false
    updateQuickEntryItem({ ...quickEntryList[index], qty: newValue }, index)
  }

  useEffect(() => {
    // Cleanup debounce on unmount
    return () => {
      debouncedLog.cancel()
    }
  }, [debouncedLog])

  return (
    <div className="container mx-auto bg-brand-light">
      {/* Table Header */}
      <div className="hidden md:flex justify-between bg-brand text-white py-3 px-4 rounded-t-md">
        <div className="w-[38%] text-left">
          {translate('text-sku-manufac-id')}
        </div>
        <div className="w-[30%] text-center">{translate('text-quantity')}</div>
        <div className="w-[30%] text-right">{translate('text-price')}</div>
      </div>

      {/* Table Rows */}
      {items.length ? (
        items.map((item, index) => {
          const { price: totalPrice } = usePrice({
            amount: item.price * item.qty,
            currencyCode: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'USD',
          })

          return (
            <div className="border-b" key={index}>
              <div className="flex flex-col md:flex-row items-center justify-between py-3 px-4 gap-4 md:gap-0">
                {/* SKU ID */}
                <div className="w-full md:w-[38%] text-left">
                  <Input
                    variant="solid"
                    placeholder="FSDSKU01"
                    className="w-full md:w-[70%]"
                    inputClassName="border-brand-gray rounded-md !min-h-10 !h-10"
                    {...register('sku' + index, {
                      required: translate('text-list-name-required', {
                        ns: 'savelist',
                      }),
                      onChange: (e) => handleInputChange(e, index),
                    })}
                    value={item.sku}
                    error={
                      item?.alreadyExists
                        ? 'sku already exists'
                        : item?.doesNotExists
                        ? "sku doesn't exist"
                        : null
                    }
                    maxLength={50}
                    data-testid={`savelist-addsku-popup-item-input(${index})`}
                  />
                </div>

                {/* Quantity */}
                <div className="flex items-center flex-col justify-center gap-2 w-full md:w-[30%]">
                  <Counter
                    value={item.qty}
                    onIncrement={() =>
                      updateQuickEntryItem(
                        { ...item, qty: item.qty + 1 },
                        index,
                      )
                    }
                    onDecrement={() =>
                      updateQuickEntryItem(
                        { ...item, qty: Math.max(item.qty - 1, 1) },
                        index,
                      )
                    }
                    onInputChange={(e) => onQtyChangeHandler(e, index)}
                    variant="cart"
                    disabled={true}
                    dataTestId={`savelist-addsku-popup-item-counter(${index})`}
                    index={index + 1}
                    className=""
                    max_sale_qty={item?.max_sale_qty}
                    min_sale_qty={item?.min_sale_qty}
                  />
                  {item?.min_sale_qty > 1 && (
                    <p className="text-brand-danger mt-1 pb-1 text-center ">
                      {translate('text-min-order-qty')}{' '}
                      <strong>{item.min_sale_qty}</strong>
                    </p>
                  )}
                </div>

                {/* Price */}
                <div
                  className="w-full md:w-[30%] text-right text-gray-600 font-semibold"
                  data-testid={`savelist-addsku-popup-item-price(${index})`}
                >
                  {totalPrice}
                </div>
              </div>
            </div>
          )
        })
      ) : (
        <p className="text-muted p-4 text-center">
          {translate('text-no-items-added')}
        </p>
      )}
    </div>
  )
}

export default SaveListPopupTable
