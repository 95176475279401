import { CUSTOMER_GROUPS, FLAGS, STOCK_TYPE } from '@constants/index'
import { Item } from '@contexts/cart/cart.utils'
import { getSession } from 'next-auth/react'
import nodeSessionStorage from 'sessionstorage-for-nodejs'
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter'
import { TYPESENSE_CONFIG } from '@framework/typesense'

type PriceDetails = {
  special_price: number
  special_from_date: string | null
  special_to_date: string | null
  price: number
}

interface ProductData {
  flags?: string[]
  stock_type?: string
}

export const formatPhoneNumber = (phoneNumberString: string) => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return ['(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return phoneNumberString
}

export const isSpecialOrder = (data?: ProductData): boolean =>
  !!data &&
  (data.flags?.includes(FLAGS.SPECIAL_ORDER) ||
    data.stock_type === STOCK_TYPE.SPECIAL_ORDER)

export const createPricePayload = ({
  special_price,
  special_from_date,
  special_to_date,
  price,
}: Item): PriceDetails => {
  return { special_price, special_from_date, special_to_date, price }
}

export const getCurrentPrice = ({
  special_price,
  special_from_date,
  special_to_date,
  price,
}) => {
  let todayDate = new Date().toISOString().split('T')[0]
  const specialPrice = Number(special_price)
  const originalPrice = Number(price)

  if (specialPrice == null) {
    return originalPrice // show price if special price null
  } else if (specialPrice && specialPrice >= originalPrice) {
    return originalPrice
  } // show original price if special price is greater
  else if (
    specialPrice &&
    (!special_from_date || special_from_date === '') &&
    (!special_to_date || special_to_date === '')
  ) {
    return specialPrice // always show special price
  } else if (
    specialPrice &&
    todayDate >= special_from_date &&
    todayDate <= special_to_date
  ) {
    return specialPrice // special price show according to date
  } else if (
    specialPrice &&
    todayDate >= special_from_date &&
    special_to_date == null
  ) {
    return specialPrice // special_to_date is null
  } else if (
    specialPrice &&
    special_from_date == null &&
    todayDate <= special_to_date
  ) {
    return specialPrice // special_from_date is null
  } else {
    return originalPrice
  }
}

export const getOldPrice = ({
  special_price,
  special_from_date,
  special_to_date,
  price,
}) => {
  let todayDate = new Date().toISOString().split('T')[0]
  const specialPrice = Number(special_price)
  const originalPrice = Number(price)
  if (specialPrice && specialPrice >= originalPrice) {
    return null
  } else if (
    specialPrice &&
    (!special_from_date || special_from_date === '') &&
    (!special_to_date || special_to_date === '')
  ) {
    return originalPrice
  } else if (
    specialPrice &&
    todayDate >= special_from_date &&
    todayDate <= special_to_date
  ) {
    return originalPrice
  } else if (
    specialPrice &&
    todayDate >= special_from_date &&
    special_to_date == null
  ) {
    return originalPrice
  } else if (
    specialPrice &&
    special_from_date == null &&
    todayDate <= special_to_date
  ) {
    return originalPrice
  }
}

export const getShipInDays = (
  ships_in_days: number,
  translate: (key: string) => string,
) => {
  let value: any, unit: string

  if (ships_in_days === 0) {
    ;[value, unit] = ['1', 'day']
  } else if (ships_in_days >= 21 && ships_in_days <= 30) {
    ;[value, unit] = ['3-4', 'week']
  } else if (ships_in_days > 30) {
    ;[value, unit] = ['6-7', 'week']
  } else {
    ;[value, unit] = [`${ships_in_days}`, 'day']
  }

  const shipsInDaysTranslationKeys = { day: 'text-day', week: 'text-week' }

  return {
    value,
    unit: translate(shipsInDaysTranslationKeys[unit]),
  }
}

export const cleanURLParams = (url) => {
  // Define unwanted characters
  const unwantedChars = /[<>#%{}/;#@%^*()!-=+,.?:|\\^~\[\]`]/g
  // Replace unwanted characters with an empty string
  return url.replace(unwantedChars, '')
}

export const getCollectionName = (locale?: string): string => {
  // If no locale provided, default to English Canada
  const normalizedLocale = locale?.toLowerCase() ?? 'en-ca'

  switch (normalizedLocale) {
    case 'en-us':
      return String(process.env.NEXT_PUBLIC_TS_EN_US_REPO ?? '')
    case 'en-ca':
      return String(process.env.NEXT_PUBLIC_TS_EN_CA_REPO ?? '')
    case 'fr-ca':
      return String(process.env.NEXT_PUBLIC_TS_FR_CA_REPO ?? '')
    default:
      throw new Error(`Unsupported locale: ${locale}`)
  }
}

export const updateSessionStore = async (sessionObj = null) => {
  const session = sessionObj ? sessionObj : await getSession()

  sessionStorage?.setItem('session', JSON.stringify(session))
  nodeSessionStorage?.setItem('session', JSON.stringify(session))
}

export const getSessionStore = () => {
  // Check if sessionStorage is defined before using it
  const sessionStore =
    typeof sessionStorage !== 'undefined' ? sessionStorage : nodeSessionStorage

  const sessionData =
    typeof sessionStorage !== 'undefined'
      ? JSON.parse(sessionStore.getItem('session'))
      : nodeSessionStorage.getItem('session')

  return sessionData
}

export const deleteSessionStore = () => {
  // Check if sessionStorage is defined before using it
  if (typeof sessionStorage !== 'undefined') {
    sessionStorage.removeItem('session')
  }
  nodeSessionStorage.removeItem('session')
}

export const getNameSlug = (brandName) => {
  return brandName
    .toLowerCase()
    .replaceAll('/', '-')
    .replaceAll('-', '')
    .replaceAll(' ', '-')
    .replaceAll('---', '-')
    .replaceAll('--', '-')
}

export const filterAndSortCustomerGroups = (
  groups: { label: string }[],
): { label: string }[] => {
  const excludedLabels: string[] = [
    CUSTOMER_GROUPS.NOT_LOGGED_IN as string,
    CUSTOMER_GROUPS.SELECT as string,
  ]

  return groups
    .filter((item) => !excludedLabels.includes(item.label))
    .sort((a, b) =>
      a.label.localeCompare(b.label, undefined, { sensitivity: 'base' }),
    )
}

export const getCategoryFilterString = (
  level: number,
  categoryName: string,
) => {
  return `is_active: true && search_visible:!= false && category_l${level}:= "${categoryName}"`
}

export const deduplicateRefinement = (items: any, translate: any) => {
  const newItems = []
  items.forEach((item: any) => {
    item.label =
      item.label.indexOf('category_l1') !== -1
        ? translate('text-department') + ':'
        : item.label.indexOf('ships_in_days') !== -1
        ? `${translate('text-ships-in')} ${translate('text-days')}:`
        : item.label
    if (
      newItems.filter((e: any) => e.attribute === item.attribute).length === 0
    ) {
      newItems.push(item)
    }
  })
  return newItems
}

export const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: TYPESENSE_CONFIG,
  additionalSearchParameters: {
    query_by:
      'name,upc,gtin,sku,mpn,category,category_l4,category_l3,category_l2,category_l1,manufacturer,brand,description',
  },
})

export const replaceDomain = (url, newDomain) => {
  // Create URL object from input
  const urlObj = new URL(url)

  // Create new URL with the new domain and original pathname + search
  const newUrl = new URL(urlObj.pathname + urlObj.search, `${newDomain}`)

  return newUrl.toString()
}
