import { QCFlag } from '@components/icons/language/QCFlag'
import siteLogo from '@assets/images/fsd_logo_canada.svg'
import siteLogoUS from '@assets/images/FSD-US-logo-blue-text.svg'
import Image from 'next/image'
import { ROUTES } from '@utils/routes'

// Define settings for each region
const siteSettingsByRegion = {
  US: {
    name: 'FoodServiceDirect.com - America’s Widest Variety in Food Service!',
    description:
      'FoodServiceDirect is your source for restaurant supplies, dry foods, refrigerated & frozen foods, paper products, and all other restaurant needs including disposable & janitorial products.',
    author: {
      name: 'FoodServiceDirect',
      websiteUrl: 'https://www.foodservicedirect.com',
      address: '',
    },
    logo: {
      url: siteLogoUS,
      alt: 'FoodServiceDirect.com - America’s Widest Variety in Food Service!',
      href: '/',
      width: 128,
      height: 30,
    },
    defaultLanguage: 'en-US',
    currencyCode: 'USD',
    canonical: 'https://www.foodservicedirect.com/',
    base_url: 'https://www.foodservicedirect.com/',
    site_header: {
      menu: [
        {
          id: 1,
          path: '/faq',
          label: 'menu-faq',
        },
        {
          id: 2,
          path: '/about-us',
          label: 'menu-about-us',
        },
        {
          id: 3,
          path: '/privacy',
          label: 'menu-privacy-policy',
        },
        {
          id: 4,
          path: '/terms',
          label: 'menu-terms-condition',
        },
        {
          id: 5,
          path: 'https://support.foodservicedirect.com/hc/en-us/requests/new',
          label: 'menu-contact-us',
        },
        {
          id: 6,
          path: '/track-order',
          label: 'menu-track-order',
        },
        {
          id: 7,
          path: ROUTES.QUICK_ORDER,
          label: 'menu-quick-order',
        },
      ],
      pagesMenu: [
        {
          id: 1,
          path: '/manufacturer',
          label: 'text-manufacturers',
        },
        {
          id: 1,
          path: '/brand',
          label: 'text-brands',
        },
        {
          id: 2,
          path: '/deals',
          label: 'text-direct-deals',
        },
        {
          id: 3,
          path: '/clearance.html',
          label: 'text-liquidation',
        },
        {
          id: 4,
          path: '/new-arrivals.html',
          label: 'text-new-arrivals',
        },
      ],
    },
  },
  CA: {
    name: "FoodServiceDirect.ca - Canada's Widest Variety in Food Service!",
    description:
      'FoodServiceDirect is your source for restaurant supplies, dry foods, refrigerated & frozen foods, paper products, and all other restaurant needs including disposable & janitorial products.',
    author: {
      name: 'FoodServiceDirect',
      websiteUrl: 'https://www.foodservicedirect.ca',
      address: '',
    },
    logo: {
      url: siteLogo,
      alt: "FoodServiceDirect.ca - Canada's Widest Variety in Food Service!",
      href: '/',
      width: 128,
      height: 30,
    },
    defaultLanguage: 'en-CA',
    currencyCode: 'CAD',
    canonical: 'https://www.foodservicedirect.ca/',
    base_url: 'https://www.foodservicedirect.ca/',
    site_header: {
      menu: [
        {
          id: 1,
          path: '/faq',
          label: 'menu-faq',
        },
        {
          id: 2,
          path: '/about-us',
          label: 'menu-about-us',
        },
        {
          id: 3,
          path: '/privacy',
          label: 'menu-privacy-policy',
        },
        {
          id: 4,
          path: '/terms',
          label: 'menu-terms-condition',
        },
        {
          id: 5,
          path: 'https://support.foodservicedirect.ca/hc/en-ca/requests/new',
          label: 'menu-contact-us',
        },
        {
          id: 6,
          path: '/track-order',
          label: 'menu-track-order',
        },
        {
          id: 7,
          path: ROUTES.QUICK_ORDER,
          label: 'menu-quick-order',
        },
      ],
      languageMenu: [
        {
          id: 'en-CA',
          name: '- EN CA',
          value: 'en-CA',
          icon: (
            <Image
              src="/assets/images/ca_flag.svg"
              alt="English Site"
              width={23}
              height={19}
            />
          ),
        },
        {
          id: 'fr-CA',
          name: '- FR',
          value: 'fr-CA',
          icon: <QCFlag />,
        },
      ],
      pagesMenu: [
        {
          id: 1,
          path: '/search',
          label: 'menu-best-deals',
        },
        {
          id: 2,
          path: '/about-us',
          label: 'menu-about-us',
        },
        {
          id: 3,
          path: '/contact-us',
          label: 'menu-contact-us',
        },
        {
          id: 4,
          path: '/faq',
          label: 'menu-faq',
        },
      ],
    },
  },
}

// Get country code from environment variable or default to 'US'
const countryCode = process.env.NEXT_PUBLIC_WEBSITE_COUNTRY || 'US'

// Dynamically select site settings based on the region
export const siteSettings = siteSettingsByRegion[countryCode]
