import { callRestApi } from '@hooks/useRestApi'
import { ReviewData, Review } from 'interfaces/pdp'

const SERVICE_URLS = {
  productReviews: (sku: string) => `/products/${sku}/reviews`,
  productWriteReview: '/products/writereview',
  customerReviews: '/fsdcustomer/reviews',
}

const getProductReviews = (sku: string) => {
  return callRestApi(SERVICE_URLS.productReviews(sku))
}

const writeProductReview = (data: any) => {
  return callRestApi(SERVICE_URLS.productWriteReview, 'post', data)
}

const getCustomerReviews = () => {
  return callRestApi(SERVICE_URLS.customerReviews)
}

const processReviewsData = (
  response: Review | Review[],
): { reviewData: Review[]; data?: ReviewData } => {
  // Ensure response is an array
  const reviews: Review[] = Array.isArray(response) ? response : [response]

  // Initialize the review data object
  const modifyData: { reviewData: Review[]; data?: ReviewData } = {
    reviewData: reviews,
  }

  if (reviews.length > 0) {
    const data: ReviewData = {
      stars: Array(5).fill(0), // Initialize an array with 5 zeros
      roundOf: 0,
      totalCount: 0,
      total: 0,
    }

    for (const review of reviews) {
      const ratingValue = review.ratings[0]?.value

      if (ratingValue && ratingValue >= 1 && ratingValue <= 5) {
        data.stars[ratingValue - 1]++
        data.totalCount++
        data.total += ratingValue
      }
    }

    if (data.totalCount > 0) {
      data.roundOf = Math.floor((data.total / data.totalCount) * 10) / 10
    }

    modifyData.data = data
  }

  return modifyData
}

const pdpReviewsService = {
  getProductReviews,
  writeProductReview,
  getCustomerReviews,
  processReviewsData,
}

export default pdpReviewsService
