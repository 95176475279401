import HeroCarouselBlock from '@components/hero/hero-carousel-block'
import Layout from '@components/layout/layout-home'
import FeaturedProductFeed from '@components/product/feeds/featured-product-feed'
import TrendingProductFeed from '@components/product/feeds/trending-product-feed'
import Seo from '@components/seo/seo'
import Container from '@components/ui/container'
import {
  fetchFeaturedProducts,
  useFeaturedProductsQuery,
} from '@framework/product/get-all-featured-products'
import { fetchTrendingProducts } from '@framework/product/get-all-trending-products'

import { LIMITS } from '@framework/utils/limits'
import { GetStaticProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { QueryClient, useQuery } from 'react-query'
import { dehydrate } from 'react-query/hydration'

import ComingSoon from '@components/coming-soon'
import CategoryGridListBlock from '@components/common/category-grid-list-block'
import { useModalAction } from '@components/common/modal/modal.context'
import { UserContext } from '@contexts/user/user.context'
import { IUser } from '@contexts/user/user.utils'
import cartServices from '@services/cartservices'
import { useRouter } from 'next/router'
import { useContext, useEffect } from 'react'
import { categories } from '../utils/main-categories'
import Divider from '@components/ui/divider'
import { DealsOfTheDay } from '@components/home-one/DealsOfTheDay'
import { TopBanners } from '@components/home-one/TopBanners'
import { DealsOfTheDayProducts } from '@components/home-one/DealsOfTheDayProducts'
import { DirectDeals } from '@components/home-one/DirectDeals'
import { FeaturedCategories } from '@components/home-one/FeaturedCategories'
import Link from '@components/ui/link'
import Image from 'next/image'
import ProductCardNew from '@components/product/product-cards/product-card-new'
import { BenefitsBar } from '@components/common/benefits-bar'
import { FollowSocialMediaBar } from '@components/common/follow-social-media-bar'
import { CustomerReviewsCarousel } from '@components/common/customer-reviews-carousel'
import { BrandHighlight } from '@components/home-one/BrandHighlight'
import { BrandHighlightMobile } from '@components/home-one/BrandHighlightMobile'
import { TopBannersMobile } from '@components/home-one/TopBannersMobile'
import { useTranslation } from 'next-i18next'

// import HeroBannersBlock from '@components/hero/hero-banners-block'

// import styles from '@styles/components/hero/hero.module.scss'

export default function Home({ locale }: any) {
  const homeAssetsPath = '/assets/images/home-one'

  const heroBannersEN = [
    `${homeAssetsPath}/Kitch-Banner-1-400x1040.jpg`,
    `${homeAssetsPath}/Kitch-Canada-Banner-2-920x516.jpg`,
    `${homeAssetsPath}/Hellmanns-Canada-Banner-3-920x516.jpg`,
    `${homeAssetsPath}/Canada-Banner-KraftHeinz-4-500x516.jpg`,
    `${homeAssetsPath}/Canada-Banner-Campbells-5-500x516.jpg`,
  ]

  const heroBannersFR = [
    `${homeAssetsPath}/Kitch-Banner-FR-1-400x1040.jpg`,
    `${homeAssetsPath}/Kitch-Canada-Banner-FR-2-920x516.jpg`,
    `${homeAssetsPath}/Hellmanns-Canada-Banner-FR-3-920x516.jpg`,
    `${homeAssetsPath}/Canada-Banner-KraftHeinz-FR-4-500x516.jpg`,
    `${homeAssetsPath}/Canada-Banner-Campbells-FR-5-500x516.jpg`,
  ]

  const heroBanners = locale === 'en-CA' ? heroBannersEN : heroBannersFR

  const {
    data: products,
    isLoading: isProductsLoading,
    error: productsError,
  } = useFeaturedProductsQuery({
    limit: LIMITS.BEST_SELLER_PRODUCTS_LIMITS,
  })

  const { openModal } = useModalAction()
  const router = useRouter()
  const { awraf } = router.query
  const comingSoonStatus = process.env.NEXT_PUBLIC_COMING_SOON_STATUS
  const { user, isGuest } = useContext(UserContext) as IUser
  const { t: translate } = useTranslation('common')

  const { data, error, isLoading } = useQuery<any>(
    ['checkreferralRewardsToken', awraf],
    () => {
      if (awraf) {
        return cartServices.checkReferralRewardsUrl(
          awraf,
          process.env.NEXT_PUBLIC_STORE_ID,
        )
      }

      return null
    },
  )

  useEffect(() => {
    if (isGuest && data?.show_popup) {
      localStorage.setItem('ref_amount', data?.ref_amount)
      openModal('REFERRAL_CREATE_ACCOUNT', { ref_amount: data?.ref_amount })
    }
    // if (localStorage.getItem('referral_show_success')) {
    //   openModal('REFERRAL_START_SHOPPING');
    //   setTimeout(() => {
    //     localStorage.removeItem('referral_show_success');
    //   }, 5000);
    // }
  }, [data])

  return (
    <>
      <Seo
        title={`${translate('breadcrumb-home')} | ${translate(
          'text-home-seo-blurb',
        )}`}
        description={translate('text-home-seo-blurb')}
        path="/"
      />
      {comingSoonStatus === 'enabled' ? (
        <ComingSoon />
      ) : (
        <>
          <Container className="page-wrap-- index-page !px-[0px]">
            <div className="w-full lg:hidden">
              <TopBannersMobile images={heroBanners} />
            </div>

            <div className="w-full hidden lg:block lg:scale-[55%] lgp:scale-[61%] xl:scale-[69%] xlp:scale-[73%] 1xl:scale-[77%] 2xl:scale-[83%] 3xl:scale-[95%] 4xl:scale-[100%] origin-[center_top] lg:-mb-[350px] xl:-mb-[250px] xlp:-mb-[200px] 1xl:-mb-[100px] 2xl:-mb-12 3xl:-mb-4 4xl:mb-20">
              <TopBanners images={heroBanners} />
            </div>

            {/* <div className="w-full hidden lg:block lg:zoom-55 lgp:zoom-61 xl:zoom-69 xlp:zoom-73 1xl:zoom-77 2xl:zoom-83 3xl:zoom-95 4xl:zoom-100">
              <TopBanners images={heroBanners} />
            </div> */}

            {/* <div className="flex flex-col justify-center pl-20 lg:flex-row items-center relative py-20 mb-20 bg-[#fafafa]">
              <DealsOfTheDay
                validity={{
                  days: 2,
                  hours: 6,
                  minutes: 33,
                  seconds: 30,
                }}
              />

              <div className="flex items-center mx-10">
                <div className="flex flex-wrap items-center justify-center clear-both">
                  <DealsOfTheDayProducts />
                </div>
              </div>
            </div> */}

            <div className="flex flex-col lg:flex-row-- mt-12 lg:-mt-10 2xl:-mt-20 3xl:mt-12 mb-[50px] lg:mb-[70px]">
              <DirectDeals locale={locale} />
            </div>

            <div className="1xl:mt-20">
              <FeaturedCategories />
            </div>

            <div className="w-full xl:hidden">
              <BrandHighlightMobile locale={locale} />
            </div>

            <div className="w-full hidden xl:flex flex-col">
              <BrandHighlight locale={locale} />
            </div>

            <div className="w-full mt-24 lg:mt-0--">
              <BenefitsBar />
            </div>

            <div className="w-full mb-12 lg:mb-24">
              <FollowSocialMediaBar />
            </div>

            <div className="w-full mb-24">
              <CustomerReviewsCarousel />
            </div>

            {/* <Container className="relative z-10">
              <CategoryGridListBlock categories={categories} />
            </Container> */}
            {/* @ts-ignore */}
            {/* <FeaturedProductFeed style={{ paddingLeft: 0 }} /> */}
            {/* @ts-ignore */}
            {/* <TrendingProductFeed style={{ paddingLeft: 0 }} /> */}
          </Container>

          {/* <Divider /> */}
        </>
      )}
    </>
  )
}

Home.Layout = Layout

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const queryClient = new QueryClient()

  await queryClient.prefetchQuery(
    ['featuredProducts', { limit: LIMITS.BEST_SELLER_PRODUCTS_LIMITS }, locale],
    fetchFeaturedProducts,
  )
  await queryClient.prefetchQuery(
    ['trendingProducts', { limit: LIMITS.BEST_SELLER_PRODUCTS_LIMITS }, locale],
    fetchTrendingProducts,
  )

  return {
    props: {
      locale,
      dehydratedState: JSON.parse(JSON.stringify(dehydrate(queryClient))),
      ...(await serverSideTranslations(locale, [
        'categories',
        'common',
        'home',
        'forms',
        'menu',
        'footer',
        'checkout',
      ])),
    },
    revalidate: 60,
  }
}
