import { useModalAction } from '@components/common/modal/modal.context'
import Button from '@components/ui/button'
import cn from 'classnames'
import { useTranslation } from 'next-i18next'
import { FC, useState } from 'react'

const VerifyAddressPopup: FC<any> = ({ data }) => {
  const { upsAddress, selectedAddress, setShippingInfo, setCallNextStep } = data
  const { t: translate } = useTranslation('checkout')

  const { closeModal } = useModalAction()

  const [currentKey, setCurrentkey] = useState(upsAddress?.length > 0 ? 1 : 0)

  const skipHandler = () => {
    setCallNextStep(true)
    closeModal()
  }

  const continueHandler = () => {
    const { city, region_code, postcode, street, address_type } =
      upsAddress[currentKey - 1]
    setShippingInfo((prevShippingInfo) => ({
      ...prevShippingInfo,
      city: city,
      zipCode: postcode,
      region_code: region_code,
      addressLineOne: street.constructor === Array ? street[0] : street,
      addressLineTwo: street?.length > 1 ? street[1] : '',
    }))
    setCallNextStep(true)
    closeModal()
  }

  return (
    <div
      className={cn('w-full md:w-[500px] lg:w-[500px] xl:w-[550px]  relative')}
    >
      <div className="mx-auto overflow-hidden font-sofia rounded-sm bg-brand-light text-brand-dark ">
        <div className="p-5">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-medium">
              {translate('text-address-verification')}
            </h2>
            <span
              className="focus:outline-none cursor-pointer"
              onClick={skipHandler}
            >
              ✕
            </span>
          </div>
          <div className="text-sm">
            <div className="mb-6">
              <h3 className="text-base font-medium ">
                {translate('text-you-selected')}
              </h3>
              <div className="mt-2 px-5  shadow-card rounded-md py-10 text-brand-gray font-medium cursor-pointer">
                {selectedAddress.addressLineOne}
                {selectedAddress.addressLineTwo
                  ? ', ' + selectedAddress.addressLineTwo
                  : ''}
                , {selectedAddress.city}, {selectedAddress.region_code},{' '}
                {selectedAddress.zipCode}
              </div>
            </div>
            <div className="mb-6">
              <h3 className="text-base font-medium">
                {' '}
                {translate('text-suggested-address')}
              </h3>
              {upsAddress.length
                ? upsAddress.map((address, k) => {
                    return (
                      <div
                        onClick={() => setCurrentkey(k + 1)}
                        className={`mt-2 px-5 border ${
                          currentKey === k + 1 ? 'border-brand-dodgerBlue' : ''
                        } cursor-pointer shadow-card rounded-md py-10 text-brand-gray font-medium`}
                      >
                        {address.street[0]}
                        {address.street[1]
                          ? ', ' + address.street[1]
                          : ''}, {address.city}, {address.region_code},{' '}
                        {address.postcode}
                      </div>
                    )
                  })
                : null}
            </div>
          </div>
        </div>
        <div className="bg-gray-100 p-3">
          <p className="text-[14px] text-brand-darkred font-sofia  font-medium leading-4 mb-2">
            {translate('text-address-verification-description')}
          </p>
          <div className="text-center">
            <Button
              className="border-solid bg-inherit border-gray-300 whitespace-nowrap px-2 mt-2 h-11 md:h-11 font-15px md:font-15px"
              variant="border"
              onClick={skipHandler}
            >
              {translate('text-skip')}
            </Button>
            <Button
              className=" whitespace-nowrap w-[120px] mt-2 ml-2 h-11 md:h-11 font-15px md:font-15px"
              variant="formButton"
              onClick={continueHandler}
            >
              {translate('text-continue')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VerifyAddressPopup
