import { callRestApi } from '@hooks/useRestApi'

const SERVICE_URLS = {
  CREATE_LIST: (listName: string) => `/saved-lists/addList/${listName}`,
  GET_SAVED_LISTS: (customerId: string, productDetails: boolean) =>
    `/saved-lists/viewList?productDetails=${productDetails}&customerId=${customerId}`,
  UPDATE_SAVED_LIST: (list_id: string) => `/saved-lists/updateList/${list_id}`,
  DELETE_SAVED_LIST: (list_id: string) => `/saved-lists/deleteList/${list_id}`,
  DELETE_SAVED_LIST_ITEM: (list_id: string, sku: string) =>
    `/saved-lists/deleteListItem/${list_id}/${sku}`,
  CREATE_ORDER_BY_SAVED_LIST: '/fsd/quick-order-by-saved-list',
  CREATE_ORDER_BY_QUICK_ENTRY: '/fsd/quick-order-by-quick-entry',
  CREATE_ORDER_BY_IMPORT_CSV: '/fsd/quick-order-by-import-csv',
  DOWNLOAD_SAMPLE_CSV: '/fsd/quick-order/download-sample-file',
}

const createNewList = async (listName, data?) => {
  return callRestApi(SERVICE_URLS.CREATE_LIST(listName), 'post', data)
}

const getSavedLists = async (customerId: string, productDetails?: boolean) => {
  if (!productDetails) productDetails = false
  return callRestApi(SERVICE_URLS.GET_SAVED_LISTS(customerId, productDetails))
}

const updateSavedList = async (list_id, data) => {
  return callRestApi(SERVICE_URLS.UPDATE_SAVED_LIST(list_id), 'post', data)
}

const deleteSavedList = async (list_id, data) => {
  return callRestApi(SERVICE_URLS.DELETE_SAVED_LIST(list_id), 'post', data)
}

const deleteSavedListItem = async ({ list_id, sku }, data) => {
  return callRestApi(
    SERVICE_URLS.DELETE_SAVED_LIST_ITEM(list_id, sku),
    'post',
    data,
  )
}

const createOrderBySavedList = async (data: { listId: string }) => {
  return callRestApi(SERVICE_URLS.CREATE_ORDER_BY_SAVED_LIST, 'post', data)
}

const createOrderByQuickEntry = async (data) => {
  return callRestApi(SERVICE_URLS.CREATE_ORDER_BY_QUICK_ENTRY, 'post', data)
}

const createOrderByImportCsv = async (data) => {
  return callRestApi(SERVICE_URLS.CREATE_ORDER_BY_IMPORT_CSV, 'post', data)
}

const downloadSampleCsv = async () => {
  return callRestApi(SERVICE_URLS.DOWNLOAD_SAMPLE_CSV)
}

const saveListService = {
  createNewList,
  getSavedLists,
  updateSavedList,
  deleteSavedList,
  deleteSavedListItem,
  createOrderBySavedList,
  createOrderByQuickEntry,
  downloadSampleCsv,
  createOrderByImportCsv,
}

export default saveListService
