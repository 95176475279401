import {
  Item,
  UpdateItemInput,
  addItemWithQuantity,
  removeItemOrQuantity,
  addItem,
  updateItem,
  removeItem,
  calculateUniqueItems,
  calculateItemTotals,
  calculateTotalItems,
  calculateTotal,
  addOrUpdateItemsWithQuantity,
} from './cart.utils'
import { hasBuyNowItems, getFilteredItems } from '@utils/buynow-item'

interface Metadata {
  [key: string]: any
}

type Action =
  | { type: 'ADD_ITEM_WITH_QUANTITY'; item: Item; quantity: number }
  | { type: 'ADD_OR_UPDATE_ITEMS'; processItems: Item[] }
  | { type: 'REMOVE_ITEM_OR_QUANTITY'; id: Item['id']; quantity?: number }
  | { type: 'REMOVE_LAST_ITEM' }
  | { type: 'ADD_ITEM'; id: Item['id']; item: Item }
  | { type: 'UPDATE_ITEM'; id: Item['id']; item: UpdateItemInput }
  | { type: 'REMOVE_ITEM'; id: Item['id'] }
  | { type: 'REMOVE_BUYNOW_ITEM'; id: Item['id'] }
  | { type: 'SET_LOGIN_USER_ITEMS'; items: Item[] }
  | { type: 'RESET_BUYNOW_ITEMS' }
  | { type: 'SET_UPDATED_CART_ITEMS'; items: Item[] }
  | { type: 'RESET_CART' }
  | { type: 'ADD_BUYNOW_ITEM_WITH_QUANTITY'; item: Item; quantity: number }

export interface State {
  items: Item[]
  buyNowItems: Item[] // New field for "Buy Now" items
  isEmpty: boolean
  totalItems: number
  totalUniqueItems: number
  total: number
  meta?: Metadata | null
  isOosItemInCart: boolean
}
export const initialState: State = {
  items: [],
  buyNowItems: [], // Initialize as empty
  isEmpty: true,
  totalItems: 0,
  totalUniqueItems: 0,
  total: 0,
  meta: null,
  isOosItemInCart: false,
}
export function cartReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'ADD_ITEM_WITH_QUANTITY': {
      // const cartItem = JSON.parse(localStorage.getItem('borobazar-cart'));
      // if (!cartItem) {
      //   alert("noor")
      //   state.items = [];
      // }
      const items = addItemWithQuantity(
        state.items,
        action.item,
        action.quantity,
      )
      return generateFinalState(state, items, state.buyNowItems)
    }
    case 'ADD_BUYNOW_ITEM_WITH_QUANTITY': {
      const buyNowItems = [{ ...action.item, quantity: action.quantity }] // Override the "Buy Now" list
      return generateFinalState(state, state.items, buyNowItems)
    }
    case 'ADD_OR_UPDATE_ITEMS': {
      const items = addOrUpdateItemsWithQuantity(
        state.items,
        action.processItems,
      )
      return generateFinalState(state, items, state.buyNowItems)
    }

    case 'REMOVE_ITEM_OR_QUANTITY': {
      const items = removeItemOrQuantity(
        state.items,
        action.id,
        (action.quantity = action.quantity ? action.quantity : 1),
      )
      return generateFinalState(state, items, state.buyNowItems)
    }
    case 'REMOVE_LAST_ITEM': {
      let items = state.items.slice(0, -1)
      return generateFinalState(state, items, state.buyNowItems)
    }
    case 'ADD_ITEM': {
      const items = addItem(state.items, action.item)
      return generateFinalState(state, items, state.buyNowItems)
    }
    case 'REMOVE_ITEM': {
      const items = removeItem(state.items, action.id)
      return generateFinalState(state, items, state.buyNowItems)
    }
    case 'REMOVE_BUYNOW_ITEM': {
      const buyNowItems = removeItem(state.buyNowItems, action.id)
      return generateFinalState(state, state.items, buyNowItems)
    }
    case 'UPDATE_ITEM': {
      const items = updateItem(state.items, action.id, action.item)
      return generateFinalState(state, items, state.buyNowItems)
    }
    case 'SET_LOGIN_USER_ITEMS': {
      return generateFinalState(initialState, action.items, state.buyNowItems)
    }
    case 'RESET_BUYNOW_ITEMS': {
      return generateFinalState(state, state.items, [])
    }
    case 'SET_UPDATED_CART_ITEMS': {
      return generateFinalState(initialState, action.items, state.buyNowItems)
    }
    case 'RESET_CART':
      return { ...initialState }

    default:
      return state
  }
}

const generateFinalState = (
  state: State,
  items: Item[],
  buyNowItems: Item[],
) => {
  const totalUniqueItems = calculateUniqueItems(items)
  const activeItems = buyNowItems?.length > 0 ? buyNowItems : items

  return {
    ...state,
    items: calculateItemTotals(items),
    buyNowItems: calculateItemTotals(buyNowItems),
    totalItems: calculateTotalItems(activeItems),
    totalUniqueItems,
    total: calculateTotal(activeItems),
    isEmpty: totalUniqueItems === 0,
    isOosItemInCart: items?.some((item) => !item?.is_in_stock),
  }
}
