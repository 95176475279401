import ContentLoader from 'react-content-loader'

const TextContentLoader = (props: any) => (
  <ContentLoader
    speed={1.5}
    width={400}
    height={50}
    viewBox="0 0 400 50"
    foregroundColor="#E7ECF3"
    className="w-full h-auto rounded-md overflow-hidden"
    {...props}
  >
    <rect x="3%" y="12" width="93%" height="4" rx="1.5" />
    <rect x="3%" y="20" width="93%" height="4" rx="1.5" />
    <rect x="3%" y="28" width="93%" height="4" rx="1.5" />
    <rect x="3%" y="36" width="93%" height="4" rx="1.5" />
    <rect x="3%" y="44" width="93%" height="4" rx="1.5" />
  </ContentLoader>
)

export default TextContentLoader
