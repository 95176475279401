import { useModalAction } from '@components/common/modal/modal.context'
import Button from '@components/ui/button'
import Input from '@components/ui/form/input'
import cn from 'classnames'
import { useTranslation } from 'next-i18next'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FC, useContext, useState } from 'react'
import { IoStarSharp } from 'react-icons/io5'
import { useMutation } from 'react-query'
import TextArea from '@components/ui/form/text-area'
import { toast } from 'react-toastify'
import { AxiosResponse } from 'axios'
import { UserContext } from '@contexts/user/user.context'
import { IUser } from '@contexts/user/user.utils'
import pdpReviewsService from '@services/pdp-reviews-service'
import { ROUTES } from '@utils/routes'
import { useRouter } from 'next/router'
interface CustomerReviewsProps {
  sku: string
}
interface ReviewFormInputs {
  title: string
  username: string
  message: string
  rating: number
}

const WriteReviews: FC<CustomerReviewsProps> = ({ sku }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ReviewFormInputs>()
  const router = useRouter()

  const { isGuest } = useContext(UserContext) as IUser

  const { t } = useTranslation()
  const { closeModal, openModal } = useModalAction()
  const [rating, setRating] = useState(0)

  function handleLogin() {
    closePopup()
    router.push(ROUTES?.LOGIN)
  }

  function closePopup() {
    closeModal()
  }
  const { mutate, isLoading, isError, isSuccess, error } = useMutation(
    (reviewData: ReviewFormInputs) => {
      return pdpReviewsService.writeProductReview({
        reviewData: {
          sku: sku,
          nickname: reviewData.username,
          title: reviewData.title,
          detail: reviewData.message,
          ratingData: [
            {
              rating_code: 'Rating',
              rating_value: reviewData.rating,
            },
          ],
        },
      })
    },
    {
      onSuccess: (response: any) => {
        if (response.status) {
          toast.success(response?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'toast-success',
          })
          closePopup()
        } else {
          toast.error(response?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'toast-error',
          })
        }
      },
      onError: (err: Error) => {
        toast.error(`Error submitting review: ${err.message}`)
      },
    },
  )
  const [hover, setHover] = useState<number>(0)
  const onSubmit: SubmitHandler<ReviewFormInputs> = (data) => {
    mutate({ ...data, rating })
  }

  if (isGuest)
    return (
      <div
        className={cn(
          'w-full md:w-[500px] lg:w-[500px] xl:w-[550px]  relative',
        )}
      >
        <div className="mx-auto overflow-hidden rounded-lg bg-brand-light p-5">
          <h1 className="text-brand-dark font-medium">Write Review</h1>
          <p className="py-5">
            Please{' '}
            <a
              className="text-brand underline cursor-pointer"
              onClick={handleLogin}
            >
              sign in
            </a>{' '}
            or purchase this item to write review.
          </p>
          <div className="text-right">
            <Button
              className=" border-solid whitespace-nowrap px-2 mt-2 tracking-normal h-11 md:h-12 font-15px md:font-15px"
              variant="border"
              onClick={closePopup}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    )

  return (
    <div
      className={cn('w-full md:w-[500px] lg:w-[500px] xl:w-[550px]  relative')}
    >
      <div className="mx-auto overflow-hidden rounded-lg bg-brand-light p-5">
        <h1 className="text-brand-dark font-medium mb-3">Write Review</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            type="text"
            placeholder="title"
            variant="solid"
            required
            {...register('title')}
            className="mb-3"
            error={errors.title?.message}
          />
          <Input
            type="text"
            placeholder="username"
            variant="solid"
            required
            {...register('username', {})}
            error={errors.username?.message}
          />
          <ul className="flex my-3 cursor-pointer">
            {Array.from({ length: 5 }, (_, index) => (
              <li key={index} onClick={() => setRating(index + 1)}>
                <IoStarSharp
                  fontSize={25}
                  className={`transition-transform transform cursor-pointer 
                ${
                  index + 1 <= (hover || rating)
                    ? 'text-yellow-ratings'
                    : 'text-gray-300'
                } 
                hover:scale-125 active:scale-150`}
                  size={25}
                  onMouseEnter={() => setHover(index + 1)}
                  onMouseLeave={() => setHover(0)}
                />
              </li>
            ))}
          </ul>

          <TextArea
            maxLength={300}
            required
            {...register('message')}
            placeholder="message"
          />
          <div className="text-right">
            <Button
              className="border-solid whitespace-nowrap px-2 mt-2 h-11 md:h-12 font-15px md:font-15px"
              variant="border"
              onClick={closePopup}
            >
              Cancel
            </Button>
            <Button
              className="border-solid whitespace-nowrap w-[120px] mt-2 ml-2 h-11 md:h-12 font-15px md:font-15px"
              variant="formButton"
              type="submit"
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default WriteReviews
